<!--
 * @FilePath: \zsw_new_edition\src\views\vip\course.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-12-09 09:43:33
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-31 10:33:29
-->
<template>
  <div class="vipBox" :style="`background-image: url(${vipBg})`">
    
    <div class="vipContentBox">
			<div class="powerBox" :style="`background: url(${minCourse}) no-repeat;`">
				<img v-if=" userInfo && userInfo.is_student == 1" class="student" src="../../assets/image/vip/student.png" alt="">
				<div class="leftBox">
					<div class="vipTitleBg">
						<img class="vipTitle" src="../../assets/image/vip/courseM.png" alt="">
						<div class="vipOriginal">原价：1299元/月</div>
					</div>
					<div class="leftBoxTop">
						<div class="powerBoxPrice1">￥<span>{{vipInfo.student_month_vip_price}}</span>元 / 月</div>
						<div class="powerBoxPrice2">
							<template v-if="vipInfo.month_tag==1 && userInfo && userInfo.is_vip && userInfo.is_vip.class && userInfo.is_vip.class.is_vip || userInfo && userInfo.is_student == 1">
								{{activity}}：{{vipInfo.month_vip_price}}
							</template>
						</div>
						<div class="powerBoxBtn" @click="pop(1)" v-if="vipInfo.month_tag==1">立即续费</div>
						<div class="powerBoxBtn" @click="pop(1)" v-else>立即购买</div>
					</div>
					<div class="leftBoxBottom">
						<ul>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">免费课程随时学（>1000节课)</li>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">VIP会员课程随时学（>2000节课）</li>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">高级助教指导学习问题</li>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">付费课程：尊享8折</li>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">专属客服服务及QQ群服务</li>
						</ul>
					</div>
					<div class="vipGive">
						<img src="../../assets/image/vip/vipGive.png" alt="">
						赠送{{vipInfo.month_vip_coin}}个知币（价值{{vipInfo.month_vip_coin}}元）
					</div>
					<img class="vipLogo" src="../../assets/image/vip/vipLogo1.png" alt="">
				</div>
				<div class="middleBox">
					<div class="vipTitleBg">
						<img class="vipTitle" src="../../assets/image/vip/courseY.png" alt="">
						<div class="vipOriginal">原价：4999元/年</div>
					</div>
					<div class="rightBoxTop">
						<div class="powerBoxPrice1">￥<span>{{vipInfo.student_year_vip_price}}</span>元 / 年</div>
						<div class="powerBoxPrice2">
							<template v-if="vipInfo.year_tag==1 && userInfo && userInfo.is_vip && userInfo.is_vip.class && userInfo.is_vip.class.is_vip || userInfo && userInfo.is_student == 1">
								{{activity}}：{{vipInfo.year_vip_price}}
							</template>
						</div>
						<div class="powerBoxBtn" @click="pop(3)" v-if="vipInfo.year_tag==1">立即续费</div>
						<div class="powerBoxBtn" @click="pop(3)" v-else>立即购买</div>
					</div>
					<div class="leftBoxBottom">
						<ul>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">免费课程随时学（>3000节课)</li>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">VIP会员课程随时学（>10000节课）</li>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">特聘老师指导学习问题</li>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">付费课程：尊享8折</li>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">专属客服服务及QQ群服务</li>
						</ul>
					</div>
					<div class="vipGive">
						<img src="../../assets/image/vip/vipGive.png" alt="">
						赠送{{vipInfo.year_vip_coin}}个知币（价值{{vipInfo.year_vip_coin}}元）
					</div>
					<div class="hotText">
						热销
					</div>
					<img class="vipLogo" src="../../assets/image/vip/vipLogo2.png" alt="">
				</div>
				<div class="rightBox">
					<div class="vipTitleBg">
						<img class="vipTitle" src="../../assets/image/vip/courseQ.png" alt="">
						<div class="vipOriginal">原价：2999元/季</div>
					</div>
					<div class="middleBoxTop">
						<div class="powerBoxPrice1">￥<span>{{vipInfo.student_season_vip_price}}</span>元 / 季</div>
						<div class="powerBoxPrice2">
							<template v-if="vipInfo.season_tag==1 && userInfo && userInfo.is_vip && userInfo.is_vip.class && userInfo.is_vip.class.is_vip || userInfo && userInfo.is_student == 1">
								{{activity}}：{{vipInfo.season_vip_price}}
							</template>
						</div>
						<div class="powerBoxBtn" @click="pop(2)" v-if="vipInfo.season_tag==1">立即续费</div>
						<div class="powerBoxBtn" @click="pop(2)" v-else>立即购买</div>
					</div>
					<div class="leftBoxBottom">
						<ul>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">免费课程随时学（>2000节课)</li>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">VIP会员课程随时学（>5000节课）</li>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">高级老师指导学习问题</li>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">付费课程：尊享8折</li>
							<li><img class="icon-caret-right" src="../../assets/image/vip/icon-caret-right.png" alt="">专属客服服务及QQ群服务</li>
						</ul>
					</div>
					<div class="vipGive">
						<img src="../../assets/image/vip/vipGive.png" alt="">
						赠送{{vipInfo.season_vip_coin}}个知币（价值{{vipInfo.season_vip_coin}}元）
					</div>
					<img class="vipLogo" src="../../assets/image/vip/vipLogo3.png" alt="">
				</div>
			</div>
		</div>
		
    <VipBottom :type="1" :fidData="fidData" :serviceImg="serviceImg"></VipBottom>
		
		<div class="headNav">
			<div class="navItem topFixation">
				<div class="activeLine">课程会员</div>
				<img class="vipCopulation" src="../../assets/image/vip/vipCopulation.png" alt="">
			</div>
			<div class="navItem">
				<router-link :to="{ path: '/vip/material' }">
					<div class="activeLine activeClass">素材会员</div>
					<img class="vipCopulation" src="../../assets/image/vip/vipCopulation.png" alt="">
				</router-link>
			</div>
			<div class="navItem bottomFixation">
				<img class="serviceIcon" src="../../assets/image/vip/serviceIcon.png" alt="">
				咨询客服
				<div class="serviceIconQrCode">
					<img  class="" :src="service.src" alt="客服">
				</div>
			</div>
			<div>
				<router-link :to="{ path: '/student/index' }" target="_blank">
					<img  class="askAreaVontent" :src="studentImg.src" alt="学生认证">
				</router-link>
			</div>
		</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getBanner, isOpenVipNew,getVipPrice, getFidSumAndPrice } from "../../Api";
import { getToken } from '../../utils/auth';
import VipBottom from "./components/VipBottom.vue";
export default {
  name: "VipMaterial",
  components:{
    VipBottom
  },
  data() {
    return {
			vipBg: '',
			service: '',
			studentImg: {
			},
			minCourse: require("../../assets/image/vip/minCourse.png"),
			
      active: 'course',
      isBig: 2,
      questionList: [],
      serviceImg: [],
			fidData: [],
      vipStatus:{},
      vipInfo:{},
			activity: '补贴价',
    };
  },
  created() {
		getBanner({ position: 2184 }).then((res) => {
			let data = res.data;
			this.vipBg = data[2].src
			this.service = data[0]
			this.studentImg = data[1]
		})
    isOpenVipNew({token:getToken(),cate:1}).then((res) => {
      this.vipStatus = res.data;
    });
    getVipPrice({token:getToken()}).then((res) => {
      this.vipInfo = res.data.class;
      this.activity = res.data.activity;
    })
    getBanner({ position: 2168 }).then((res) => {
			this.serviceImg = res.data
    })
		getFidSumAndPrice({type: 1}).then((res) => {
			this.fidData = res.data
    })
  },
  methods: {
    pop(type) {
      //先判断是否登录
      if (!this.isLogin) {
        this.$login();
        return;
      }
      let data = {
        title: "课程VIP会员",
        typeTitle: "",
        balance: this.balance,
        money: 0,
        type: type,
        contents: [],
        cate: 1,
      };
      if (type == 1) {
        data.contents = [
          "免费课程随时学（>1000节课)",
          "VIP会员课程随时学（>2000节课，总价值>10万元）",
          `赠送个${this.vipInfo.month_vip_coin}知币（价值${this.vipInfo.month_vip_coin}元）`,
          "高级助教指导学习问题",
          `付费课程：尊享8折`,
          "专属客服服务及QQ群服务",
        ];
        data.typeTitle = "月度VIP会员";
        data.money = this.vipInfo.student_month_vip_price
      }
      if (type == 2) {
        data.contents = [
          "免费课程随时学（>2000节课)",
          "VIP会员课程随时学（>5000节课）",
          `赠送${this.vipInfo.season_vip_coin}个知币（价值${this.vipInfo.season_vip_coin}元）`,
          "高级老师指导学习问题",
          `付费课程：尊享8折`,
          "专属客服服务及QQ群服务",
        ];
        data.typeTitle = "季度VIP会员";
        data.money = this.vipInfo.student_season_vip_price
      }
      if (type == 3) {
        data.contents = [
          "免费课程随时学（>3000节课)",
          "VIP会员课程随时学（>10000节课）",
          `赠送${this.vipInfo.year_vip_coin}个知币（价值${this.vipInfo.year_vip_coin}元）`,
          "特聘老师指导学习问题",
          `付费课程：尊享8折`,
          "专属客服服务及QQ群服务",
        ];
        data.typeTitle = "年度VIP会员";
        data.money = this.vipInfo.student_year_vip_price
      }
      this.$vipPop({ paramss: data });
    },
  },
  computed: {
    ...mapGetters(["balance", "isLogin",'userInfo']),
  },
};
</script>

<style scoped>
.vipBox{
  /* width:12.64rem; */
	width: 100%;
	padding-top: 4.64rem;
  margin: auto;
  font-family: 'PingFang SC';
  text-align: center;
	background-repeat: no-repeat !important;
	background-size: 100% !important;
	/* background-position: 0 -0.68rem; */
}
.vipContentBox {
	width: 12rem;
	margin: 0 auto;
}
.powerBox {
	position: relative;
	width: 12rem;
	height: 8.66rem;
	background-color: antiquewhite;
	background-size: 100% !important;
  display: flex;
  justify-content: space-between;
	padding: 1.17rem 0.32rem 0.55rem;
	
}
.student {
	width: 0.58rem;
	height: 0.22rem;
	position: absolute;
	top: 0.56rem;
	left: calc(50% - 0.29rem);
}
.powerBox>div{
  width:3.6rem;
	height: 6.9rem;
  border-radius: 0.16rem;
  position: relative;
	overflow: hidden;
	background-color: #fff;
}

.leftBox {
	/* background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 215, 196, 1) 100%); */
}
.middleBox {
	/* background: linear-gradient(0deg, rgba(255, 211, 107, 1) 0%, rgba(255, 255, 255, 1) 100%); */
	border: 4px solid rgba(255, 73, 33, 1);  
}
.rightBox {
	/* background: linear-gradient(0deg, rgba(201, 201, 242, 1) 0%, rgba(255, 255, 255, 1) 100%); */
}

.leftBox .vipTitleBg{
	height: 0.9rem;
	background: linear-gradient(90deg, rgba(247, 212, 196, 1) 0%, rgba(247, 212, 196, 0) 100%);
}
.middleBox .vipTitleBg{
	height: 0.9rem;
	background: linear-gradient(90deg, rgba(255, 211, 99, 1) 0%, rgba(255, 211, 99, 0) 100%);
}
.rightBox .vipTitleBg{
	height: 0.9rem;
	background: linear-gradient(90deg, rgba(196, 196, 196, 1) 0%, rgba(235, 235, 235, 1) 100%);
}

.vipLogo {
	position: absolute;
	top: -0.24rem;
	left: -0.24rem;
	width: 1.14rem;
	height: 1.14rem;
}
.hotText {
	position: absolute;
	top: 0.1rem;
	right: -0.27rem;
	width: 1.1rem;
	height: 0.32rem;
	text-shadow: 0px 2px 2px rgba(163, 35, 0, 1);
	font-size: 0.14rem;
	line-height: 0.32rem;
	font-weight: 500;
	letter-spacing: 0px;
	color: rgba(255, 255, 255, 1);
	text-align: center;
	background: linear-gradient(90deg, rgba(255, 185, 153, 1) 0%, rgba(255, 43, 0, 1) 100%);
	transform: rotate(45deg);
}
.vipTitle {
	width: 1.66rem;
	height: 0.31rem;
	margin: 0.24rem auto 0.02rem;
}
.vipOriginal {
	width: 100%;
	font-size: 0.16rem;
	font-weight: 500;
	color: rgba(152, 125, 81, 1);
	text-align: center;
}
.vipGive {
	width: 2.88rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
	font-size: 0.18rem;
	white-space: nowrap;
	font-weight: 500;
	letter-spacing: 0px;
	color: rgba(230, 68, 0, 1);
}
.vipGive img {
	width: 0.48rem;
	height: 0.48rem;
	margin-right: 0.08rem;
}
.powerBoxPrice1{
  height: 0.54rem;
  font-size: 0.2rem;
  font-weight: 700;
  text-align: center;
  margin: 0.32rem auto 0.08rem;
	letter-spacing: 0px;
	color: rgba(126, 93, 38, 1);
}
.powerBoxPrice1>span{
  font-size: 0.46rem;
}
.powerBoxPrice2{
  height: 0.17rem;
  font-size: 0.14rem;
	font-weight: 400;
	letter-spacing: 0px;
	text-decoration-line: line-through;
	color: rgba(126, 93, 38, 0.8);
}
.powerBoxBtn{
  width: 2.88rem;
  height: 0.54rem;
	margin: 0.32rem;
  border-radius: 0.08rem;
  background: rgba(229, 160, 131, 1);
  box-shadow:inset -6px -6px 12px 0px rgba(219, 115, 70, 0.6),inset 6px 6px 12px 0px rgba(252, 205, 184, 1);
  cursor: pointer;
	font-size: 0.22rem;
	font-weight: 700;
	letter-spacing: 0px;
	color: #fff;
	line-height: 0.54rem;
}
.rightBoxTop .powerBoxBtn {
	background: rgba(255, 193, 36, 1);
	box-shadow:inset -6px -6px 12px 0px rgba(232, 166, 0, 1),inset 6px 6px 12px 0px rgba(255, 236, 189, 1);
}
.middleBoxTop .powerBoxBtn {
	background: rgba(145, 145, 180, 1);
	box-shadow:inset -6px -6px 12px 0px rgba(114, 114, 148, 1),inset 6px 6px 12px 0px rgba(171, 171, 199, 1);
}
.leftBoxBottom {
  width: 2.88rem;
	padding: 0.32rem 0;
  margin: auto;
	border-top: 1px solid rgba(229, 160, 131, 0.1);
}
.leftBoxBottom ul li {
  height: 0.22rem;
  color: rgba(122, 93, 82, 1);
  font-size: 0.16rem;
  font-weight: 500;
  margin-top:0.24rem;
	text-align: left;
  font-family: 'PingFang SC';
	white-space: nowrap;
}
.leftBoxBottom ul li:nth-child() {
  margin-top: 0;
}
.icon-caret-right {
	display: inline-block;
	width: 0.12rem;
	height: 0.12rem;
	margin-right: 0.16rem;
}
.headNav{
  width: 2rem;
	position: fixed;
	top: 3.5rem;
	right: 0.6rem;
}
.navItem {
	cursor: pointer;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2rem;
	height: 0.8rem;
	margin-bottom: 0.08rem;
	border-radius: 0.08rem;
	background: rgba(255, 207, 122, 1);
}
.topFixation::after {
	content: '';
	position: absolute;
	top: 0.06rem;
	left: 0.08rem;
	width: 0.08rem;
	height: 0.08rem;
	border-radius: 0.04rem;
	opacity: 1;
	background: radial-gradient(closest-side at 50% 50%, rgba(252, 139, 86, 1) 0%, rgba(237, 111, 52, 1) 100%);
	box-shadow: 0 0 0.04rem 0 rgba(120, 38, 0, 0.3);
	filter: blur(0.01rem);
}
.topFixation::before {
	content: '';
	position: absolute;
	top: 0.06rem;
	right: 0.08rem;
	width: 0.08rem;
	height: 0.08rem;
	border-radius: 0.04rem;
	opacity: 1;
	background: radial-gradient(closest-side at 50% 50%, rgba(252, 139, 86, 1) 0%, rgba(237, 111, 52, 1) 100%);
	box-shadow: 0 0 0.04rem 0 rgba(120, 38, 0, 0.3);
	filter: blur(0.01rem);
}

.bottomFixation::after {
	content: '';
	position: absolute;
	bottom: 0.06rem;
	left: 0.08rem;
	width: 0.08rem;
	height: 0.08rem;
	border-radius: 0.04rem;
	opacity: 1;
	background: radial-gradient(closest-side at 50% 50%, rgba(252, 139, 86, 1) 0%, rgba(237, 111, 52, 1) 100%);
	box-shadow: 0 0 0.04rem 0 rgba(120, 38, 0, 0.3);
	filter: blur(0.01rem);
}
.bottomFixation::before {
	content: '';
	position: absolute;
	bottom: 0.06rem;
	right: 0.08rem;
	width: 0.08rem;
	height: 0.08rem;
	border-radius: 0.04rem;
	opacity: 1;
	background: radial-gradient(closest-side at 50% 50%, rgba(252, 139, 86, 1) 0%, rgba(237, 111, 52, 1) 100%);
	box-shadow: 0 0 0.04rem 0 rgba(120, 38, 0, 0.3);
	filter: blur(0.01rem);
}
.bottomFixation {
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0px;
	color: rgba(21, 150, 17, 1);
	text-shadow: 1px 1px 1px rgba(211, 255, 209, 1);
	vertical-align: top;
}
.serviceIcon {
	width: 0.44rem;
	height: 0.44rem;
	margin-right: 0.15rem;
}
.bottomFixation:hover .serviceIconQrCode{
	display: block;
	opacity: 1;
	transition: 1s;
}
.serviceIconQrCode {
	display: none;
	opacity: 0;
	position: absolute;
	left: -1.68rem;
	top: -0.00rem;
	width: 1.6rem;
	height: 1.6rem;
	border-radius: 0.08rem;
	padding: 0.08rem;
	background-color: #fff;
	z-index: 99;
	transition: 1s;
}
.serviceIconQrCode img {
	width: 1.44rem;
	height: 1.44rem;
}
.activeLine {
	width: 1.68rem;
	height: 0.44rem;
	display: flex;
	justify-content: center;
	align-items: center; 
	font-size: 0.16rem;
	opacity: 1;
	font-weight: 700;
	border-radius: 0.22rem;
	color: rgba(255, 255, 255, 1);
	text-shadow: 0px 0px 20px 0px rgba(124, 255, 120, 1);
	background: rgba(18, 148, 13, 1);
	box-shadow:inset 0px -2px 6px 0px rgba(0, 0, 0, 0.25),inset 0px 2px 1px 0px rgba(187, 217, 101, 1);
}
.vipCopulation {
	width: 1.56rem;
	height: 0.22rem;
	position: absolute;
	left: 0.22rem;
	bottom: -0.15rem;
	z-index: 9;
}
.askAreaVontent {
	width: 2rem;
	margin-top: 0.32rem;
}
.activeClass{
	color: rgba(21, 151, 16, 1);
	text-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 1);
	background: rgba(244, 255, 209, 1);
	box-shadow:inset 0px -2px 6px 0px rgba(196, 201, 183, 1),inset 0px 2px 1px 0px rgba(255, 255, 255, 1);
}
</style>